<!-- Start Page Title Area -->
<div class="page-title-area item-bg3">
  <div class="container">
    <div class="page-title-content">
      <h2>Quelques projets réalisés</h2>
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li>Projets</li>
      </ul>
    </div>
  </div>

  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
</div>
<!-- End Page Title Area -->

<!-- Start Projects Area -->
<section class="projects-area ptb-110">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-projects-box">
          <img src="assets/img/projects/360.png" alt="image" />
          <div class="plus-icon">
            <a routerLink="/projects/clarize-360">
              <span></span>
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-projects-box">
          <img src="assets/img/projects/go-express.png" alt="image" />
          <div class="plus-icon">
            <a routerLink="/projects/go-express-senegal">
              <span></span>
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-projects-box">
          <img src="assets/img/partner/petrosen.jpg" alt="image" />
          <div class="plus-icon">
            <a routerLink="/projects/platforme-pubc">
              <span></span>
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-projects-box">
          <img src="assets/img/projects/nsia.png" alt="image" />
          <div class="plus-icon">
            <a routerLink="/projects/nsia-digitalisation">
              <span></span>
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-projects-box">
          <img src="assets/img/projects/wutiko-thumb.png" alt="image" />
          <div class="plus-icon">
            <a routerLink="/projects/wutiko">
              <span></span>
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-projects-box">
          <img src="assets/img/projects/hebdomagazine.png" alt="image" />
          <div class="plus-icon">
            <a routerLink="/projects/hebdomagazine">
              <span></span>
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-projects-box">
          <img src="assets/img/projects/q-checking-thumb.PNG" alt="image" />
          <div class="plus-icon">
            <a routerLink="/projects/platforme-q-checking">
              <span></span>
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-projects-box">
          <img src="assets/img/projects/onudi-cover.png" alt="image" />
          <div class="plus-icon">
            <a routerLink="/projects/onudi">
              <span></span>
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-projects-box">
          <img src="assets/img/projects/geo-etude-cover.PNG" alt="image" />
          <div class="plus-icon">
            <a routerLink="/projects/geo-etudes-plus">
              <span></span>
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-projects-box">
          <img src="assets/img/projects/opendatathumb.PNG" alt="image" />
          <div class="plus-icon">
            <a routerLink="/projects/open-data">
              <span></span>
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-projects-box">
          <img src="assets/img/partner/sen-uniform.png" alt="image" />
          <div class="plus-icon">
            <a routerLink="/projects/sen-uniforme">
              <span></span>
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-projects-box">
          <img src="assets/img/projects/gythumb.PNG" alt="image" />
          <div class="plus-icon">
            <a routerLink="/projects/grand-yoff-gis">
              <span></span>
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-projects-box">
          <img src="assets/img/projects/ndayane1.PNG" alt="image" />
          <div class="plus-icon">
            <a routerLink="/projects/port-ndayane">
              <span></span>
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-projects-box">
          <img src="assets/img/projects/presidentielcover.PNG" alt="image" />
          <div class="plus-icon">
            <a routerLink="/projects/presidentiel-2019">
              <span></span>
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-projects-box">
          <img src="assets/img/projects/ter-thumb.PNG" alt="image" />
          <div class="plus-icon">
            <a routerLink="/projects/impact-ter">
              <span></span>
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-projects-box">
          <img src="assets/img/projects/geosmarketing-thumb.PNG" alt="image" />
          <div class="plus-icon">
            <a routerLink="/projects/geomarketing">
              <span></span>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-projects-box">
          <img src="assets/img/projects/srjthumb.PNG" alt="image" />
          <div class="plus-icon">
            <a routerLink="/projects/srj-saint-louis">
              <span></span>
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-projects-box">
          <img src="assets/img/projects/sorec-thumb.PNG" alt="image" />
          <div class="plus-icon">
            <a routerLink="/projects/sorec-site">
              <span></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="circle-shape1">
    <img src="assets/img/projects-shape.png" alt="image" />
  </div>
</section>
<!-- End Projects Area -->
