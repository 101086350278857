<!-- Start mission Area -->
<section class="webinar-area">
  <div class="row m-0">
    <div class="col-lg-6 p-0">
      <div class="webinar-content">
        <h2>Notre mission</h2>
        <p>Clarize, votre partenaire technologique proche.</p>
        <p>
          Vous aider sur vos opérations de transformations digitales, sur
          l'automatisme et la gestion de vos routines, sur la mise en place de
          solutions technologiques pour une meilleure rentabilité de vos
          activités, notre Mission.
        </p>
        <!-- <a routerLink="/about" class="btn btn-primary">Watch More</a> -->
      </div>
    </div>

    <div class="col-lg-6 p-0">
      <div class="webinar-video-image mission">
        <img src="assets/img/mission.jpg" alt="image" />
        <a
          href="https://www.youtube.com/watch?v=6GdlaLwNeGU"
          class="video-btn popup-youtube"
          ><i class="flaticon-play-button"></i
        ></a>
      </div>
    </div>
  </div>
</section>
<!-- End mission Area -->

<!-- Start vison Area -->
<section class="webinar-area vision-bloc">
  <div class="row m-0">
    <div class="col-lg-6 p-0">
      <div class="webinar-video-image vision">
        <img src="assets/img/vision.jpg" alt="image" />
        <!-- <a
          href="https://www.youtube.com/watch?v=bk7McNUjWgw"
          class="video-btn popup-youtube"
          ><i class="flaticon-play-button"></i
        ></a> -->
      </div>
    </div>
    <div class="col-lg-6 p-0">
      <div class="webinar-video">
        <!-- href="https://www.youtube.com/watch?v=bk7McNUjWgw" -->
        <a
          href="https://www.youtube.com/watch?v=6GdlaLwNeGU"
          class="video-btn popup-youtube"
          ><i class="flaticon-play-button"></i
        ></a>
        <div class="webinar-content">
          <h2>Notre vison</h2>
          <p>Clarize, un écosystème de solutions technologiques pour vous.</p>
          <p>
            Devenir le pôle technologique de l'Afrique, participer à une
            décentralisation des dernières avancés technologiques partout en
            Afrique, notre Vision.
          </p>
          <!-- <a routerLink="/about" class="btn btn-primary">Watch More</a> -->
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End vison Area -->
