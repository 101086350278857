import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
// import { catchError } from 'rxjs/operators';
import { of } from "rxjs";
import { environment } from "src/environments/environment";
import {
  Firestore,
  collectionData,
  collection,
  doc,
} from "@angular/fire/firestore";
import { v4 as uuidv4 } from "uuid";
import { setDoc } from "@angular/fire/node_modules/@firebase/firestore";

export interface MailRequestParams {
  sender: {
    email: string;
    name: string;
  };
  to: {
    email: string;
    name: string;
  };
  details: {
    subject: string;
    date: Date | string;
    body: string;
    amount?: number;
    tel?: number;
    attachedFile?:
      | {
          name: string;
          size: number;
          url: string;
          icon: string;
          created_at: Date;
          fileType: string;
        }
      | {};
  };
}

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class MailerService {
  mailerUlr = "https://clarize-apis.herokuapp.com/api/email";
  constructor(private http: HttpClient, private db: Firestore) {}

  postMail(data: any) {
    // data.details.body = `
    //     ${data.details.body}
    //     <br>
    //     <br>
    //     <br>

    //     <h3>L'équipe Pétrosen Trading & Services</h3>
    //     <img src="https://drive.google.com/file/d/1XmZd_81er5I5QaLLMDQcPlrLsKLkI6Th/view?usp=sharing" alt="logo Pétrosen"/>
    //     `;
    // return (
    //   this.http
    //     .post(`${environment.api2Url}/mail`, data, httpOptions)
    //     // .pipe(catchError((err) => this.handleError(err, data)))
    //     .toPromise()
    // );
    return this.http.post(`${environment.apiUrl}/email`, data, httpOptions);
    // .pipe(catchError((err) => this.handleError(err, data)))
    // .toPromise()
  }

  saveMail(email: string, name: string = "") {
    const data = {
      id: uuidv4(),
      date: new Date(),
      email,
      name,
    };
    return setDoc(doc(this.db, `subscription/${data.id}`), data);
  }

  handleError(err: any, mail: MailRequestParams) {
    return of({ err, mail });
  }
}
