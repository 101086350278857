import { Component, OnInit } from "@angular/core";
import { TestimonialsService } from "src/app/services/testimonials.service";

@Component({
  selector: "app-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.scss"],
})
export class FeedbackComponent implements OnInit {
  testimonials: {
    name: string;
    title: string;
    photo: string;
    content: string;
    cover: string;
  }[];

  constructor(private testimonailService: TestimonialsService) {}

  ngOnInit() {
    this.testimonials = this.testimonailService.testimonials;
  }
}
