<!-- Start Error Area -->
<section class="error-area">
  <div class="container">
    <div class="error-content">
      <img src="assets/img/404.jpg" alt="error" />
      <h3>Page introuvable...</h3>
      <p>
        <!-- The page you are looking for might have been removed had its name changed or is temporarily unavailable. -->
      </p>
      <a routerLink="/" class="btn btn-primary">Retour vers accueil</a>
    </div>
  </div>
</section>
<!-- End Error Area -->
