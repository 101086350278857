import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  styles: [
    `
    
    img {
      filter: grayscale(0);
    }`
  ]
})
export class OurTeamComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
