import { Component, OnInit } from "@angular/core";
import { MailerService } from "src/app/services/mailer.service";
import { NotifierService } from "src/app/services/notifier.service";

@Component({
  selector: "app-free-trial-area",
  templateUrl: "./free-trial-area.component.html",
  styleUrls: ["./free-trial-area.component.scss"],
})
export class FreeTrialAreaComponent implements OnInit {
  email: string;
  name: string;
  isSaving = false;
  constructor(
    private emailService: MailerService,
    private notification: NotifierService
  ) {}

  ngOnInit() {}

  async subscribeToNewsletter() {
    // console.log("ddd");
    this.isSaving = true;
    try {
      const res = await this.emailService.saveMail(this.email, this.name);
      this.notification.successNofication("Souscription avec succès");
      this.email = "";
      this.name = "";
    } catch (error) {
      // console.log(error);
      this.notification.errorNofication(
        "Souscription échouée, veuillez réessayer"
      );
    } finally {
      this.isSaving = false;
    }
  }
}
