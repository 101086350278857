import { Injectable } from "@angular/core";
export interface Project {
  name: string;
  slug: string;
  imgs: {
    thumbnail: string;
    cover: string;
    img1: string;
    img2: string;
  };
  customer: string;
  category: string;
  startDate: string;
  endDate: string;
  status: string;
  demos: {
    android?: string;
    ios?: string;
    web: string;
  };
  tags: string;
  description: string[];
  customerQuote: string;
}
@Injectable({
  providedIn: "root",
})
export class ProjectsService {
  listProjects: Project[] = [
    {
      name: "Clarize 360",
      slug: "clarize-360",
      imgs: {
        thumbnail: "assets/img/projects/360.png",
        cover: "assets/img/projects/clarize-cover.PNG",
        img1: "assets/img/projects/clarize1.PNG",
        img2: "assets/img/projects/clarize2.PNG",
      },
      customer: "Clarize",

      category: "Software, Machine learing, IT, Cloud",
      startDate: "Fevrier 2019",
      endDate: "En cours de test",
      status: "En développement",
      demos: {
        // android: "https://goexpress-online.com",
        // ios: "https://goexpress-online.com",
        web: "https://open-communication-17397.web.app/",
      },
      tags: "App, Design, Web, Mobile, Angular,PWA, Firebase, Dev, AI, Chatbots, ERP, CRM",
      description: [
        "Sur ce on peut faire mieux en proposant un système beaucoup plus complet :",
        ". Possibilité d’enregistrer leurs activités (Visioconférences, présentations vidéo, …) et le partager sur les réseaux sociaux (Facebook, LinkedIn, Google Plus et Twitter).",
        ". Support de visioconférence avec le nombre de participants basé sur le plan du user (free -> 5, premium ->25).",
        ". Intégration avec un système de gestion des fournisseurs, et un CRM beaucoup plus avancé",
        ". Plus de flexibilité le client choisit les fonctionnalités qui l’intéressent.",
        ". Un Map regroupant les clients, les employés et les fournisseurs.",
        ". Logs des activités pour l’administrateur du site (Historiques des ventes, achats, préts, appels, des visioconférences avec les durées et les nombres de participants, …).",
        ". Une automisation de la compabilité de l'entreprise.",
        ". Forum pour permettre aux users de s’entre aider.",
        ". Un blog pour rester informer des dernières actualités, pour permettre aussi aux users de partager leurs expériences, …",
        ". GED et Intégration avec Google drive (compte de l’entreprise pour les docs).",
        ". Un système de follow (partnership entre entreprises).",
      ],
      customerQuote:
        "Avoir une vision 360° sur tout ce qui passe sur votre business.",
    },
    {
      name: "Wutiko",
      slug: "wutiko",
      imgs: {
        thumbnail: "assets/img/projects/wutiko-thumb.png",
        cover: "assets/img/projects/wutiko-cover.jpg",
        img1: "assets/img/projects/wutiko1.jpg",
        img2: "assets/img/projects/wutiko2.jpg",
      },
      customer: "Wutiko SAS",
      category: "Software",
      startDate: "Avril 2021",
      endDate: "Juillet 2021",
      status: "Terminé",
      demos: {
        android: "En développement",
        ios: "En développement",
        web: "https://cjs-wutiko.herokuapp.com/",
      },
      tags: "App, Design, Web, Angular, PWA, Mobile, Dev, IOS, Android",
      description: [""],
      customerQuote:
        "C'est trés professionel et intelligemment réalisé avec facilité.",
    },
    {
      name: "Go Express Sénégal",
      slug: "go-express-senegal",
      imgs: {
        thumbnail: "assets/img/projects/go-express.png",
        cover: "assets/img/projects/go-express-cover.png",
        img1: "assets/img/projects/new order.jpg",
        img2: "assets/img/projects/trajet livraison.jpg",
      },
      customer: "Go Express Sénégal",

      category: "Software, BI, Cloud",
      startDate: "Août 2020",
      endDate: "Octobre 2020",
      status: "Terminé",
      demos: {
        android:
          "https://play.google.com/store/apps/details?id=org.goexpress.goexpressonline",
        ios: "https://apps.apple.com/us/app/go-express-sénégal/id1548715023",
        web: "https://goexpress-online.com",
      },
      tags: "App, Design, Web, Angular, PWA, Firebase, GraphQL, Mobile, Dev, Android, IOS",
      description: [""],
      customerQuote:
        "On pensait pas que le système de gestion automatique des livraisons, serait facilement réalisé et fonctionnel comme UBER.",
    },
    {
      name: "Plateforme PUBC",
      slug: "platforme-pubc",
      imgs: {
        thumbnail: "assets/img/partner/petrosen.jpg",
        cover:
          "https://www.bosch-thermotechnology.com/ocsmedia/optimized/1600x560/o362033v296_skv_Komponenten_Dampfkessel_1.jpg",
        img1: "assets/img/projects/petrosen valider commande.png",
        img2: "assets/img/projects/petrosen-dashboard.png",
      }, 
      customer: "Pétrosen Trading & Services",
      category: "Software, Cloud",
      startDate: "Novembre 2020",
      endDate: "Décembre 2021",
      status: "Terminé",
      demos: {
        // android: 'goexpress-online.com',
        // ios: 'goexpress-online.com',
        web: "https://www.pubc-petrosents.com",
      },
      tags: "App, Design, Angular, PWA, Web, Dev, Bootstrap",
      description: [
        "Le projet a pour objectif général de dématérialiser la gestion des bons d’essence allant des processus de commandes, passant par la distribution jusqu’au paiement au niveau des stations grâce à une application mobile.",
        "La plateforme vise un public professionnel, pour développer l’activité en B2B plus précisément avec l’administration Sénégalaise.",
        "Dans un premier temps, ce segment de marché sera adressé en exclusivité : les ministères, les cabinets étatiques, la présidence, les hautes institutions de l’Etat, ...",
      ],
      customerQuote: "La plateforme est robuste et solide. Good job.",
    },
    {
      name: "Plateforme Q-checking",
      slug: "platforme-q-checking",
      imgs: {
        thumbnail: "assets/img/partner/q-checking-thumb.PNG",
        cover: "assets/img/projects/q-checking-cover.PNG",
        img1: "assets/img/projects/q-checking1.PNG",
        img2: "assets/img/projects/q-checking2.PNG",
      },
      customer: "SAS",
      category: "Software, Cloud",
      startDate: "Juillet 2021",
      endDate: "Septembre 2021",
      status: "Terminé",
      demos: {
        // android: 'goexpress-online.com',
        // ios: 'goexpress-online.com',
        web: "https://q-checking.herokuapp.com/",
      },
      tags: "App, Design, Angular, PWA, Web, Dev, NodeJs, ExpressJs, MongoDB, Mongoose, Bootstrap, JWT, Passport",
      description: [""],
      customerQuote: "La plateforme est innonvate et simple d'utilisation.",
    },
    {
      name: "Plateforme de développement de Grand-Yoff",
      slug: "grand-yoff-gis",
      imgs: {
        thumbnail: "assets/img/projects/gythumb.PNG",
        cover: "assets/img/projects/gy-cover.PNG",
        img1: "assets/img/projects/gy-1.PNG",
        img2: "assets/img/projects/gy-cover.PNG",
      },
      customer: "Clarize",
      category: "Software",
      startDate: "Novembre 2020",
      endDate: "Décembre 2021",
      status: "Terminé",
      demos: {
        // android: 'goexpress-online.com',
        // ios: 'goexpress-online.com',
        web: "https://grand-yoff-digital.firebaseapp.com/",
      },
      tags: "App, Design, Web, Angular, PWA, Dev, SIG, Télédétection, Webmapping, Leaflet, Cartographie",
      description: [""],
      customerQuote: "La Géomatique pour servir les collectivités locale.",
    },
    {
      name: "Impact du TER sur la commune Hann Bel Air",
      slug: "impact-ter",
      imgs: {
        thumbnail: "assets/img/projects/ter-thumb.PNG",
        cover: "assets/img/projects/ter-cover.PNG",
        img1: "assets/img/projects/ter-1.PNG",
        img2: "assets/img/projects/ter-2.PNG",
      },
      customer: "Clarize",
      category: "Software",
      startDate: "Novembre 2020",
      endDate: "Décembre 2021",
      status: "Terminé",
      demos: {
        // android: 'goexpress-online.com',
        // ios: 'goexpress-online.com',
        web: "https://hann-bel-air.web.app/",
      },
      tags: "App, Design, Web, Angular, PWA, Dev, SIG, Télédétection, Webmapping, Leaflet, Cartographie",
      description: [""],
      customerQuote:
        "La Géomatique pour la maitrise des opérations de recasement.",
    },
    {
      name: "NSIA Digitalisation",
      slug: "nsia-digitalisation",
      imgs: {
        thumbnail: "assets/img/partner/nsia.png",
        cover: "assets/img/projects/nsia-cover.PNG",
        img1: "assets/img/projects/nsia1.PNG",
        img2: "assets/img/projects/nsia2.png",
      },
      customer: "NSIA Assurance",
      category: "Software",
      startDate: "Février 2021",
      endDate: "Avril 2021",
      status: "En cours - updates",
      demos: {
        android:
          "https://play.google.com/store/apps/details?id=org.nativescript.nsiaclient",
        ios: "https://apps.apple.com/us/app/nsia-connect-senegal/id1594734492",
        web: "https://www.nsia-digital.com",
      },
      tags: "App, Design, Angular, PWA, Web, Dev, Mobile, Android, IOS",
      description: [""],
      customerQuote: "C'est un excellent travail sur un temps record.",
    },
    {
      name: "Blog Hebdomagazine",
      slug: "hebdomagazine",
      imgs: {
        thumbnail: "assets/img/projects/hebdomagazine.png",
        cover: "assets/img/projects/hebdomagazine_cover.PNG",
        img1: "assets/img/projects/hebdomagazine1.PNG",
        img2: "assets/img/projects/hebdomagazine2.PNG",
      },
      customer: "Hebdomagazine",
      category: "Website/PWA",
      startDate: "Novembre 2021",
      endDate: "Décembre 2021",
      status: "En production",
      demos: {
        // android:
        //   "https://play.google.com/store/apps/details?id=org.nativescript.nsiaclient",
        // ios: "https://apps.apple.com/us/app/nsia-connect-senegal/id1594734492",
        web: "https://hebdomagazine.com",
      },
      tags: "App, Design, Angular, PWA, Web, Dev, Blog, Articles",
      description: [""],
      customerQuote: "Le site est simple et très bien réalisé.",
    },
    {
      name: "Plateformes Sen Uniform",
      slug: "sen-uniforme",
      imgs: {
        thumbnail: "assets/img/projects/sen-uniform.png",
        cover: "assets/img/projects/sen-uniform-cover.PNG",
        img1: "assets/img/projects/sen-uniform1.PNG",
        img2: "assets/img/projects/senuniform.PNG",
      },
      customer: "Sén-Uniforme",
      category: "Software, Data Analysts, BI, Cloud",
      startDate: "Juillet 2020",
      endDate: "Septembre 2020",
      status: "terminé",
      demos: {
        // android: 'goexpress-online.com',
        // ios: 'goexpress-online.com',
        web: "https://senuniforme.com",
        // web2: "https://senuniforme.com",
      },
      tags: "App, Angular, Desktop, PWA, Firebase, Wordpress, PHP, MySQL, Woocommerce, Web, Dev, BI",
      description: [""],
      customerQuote: "Les outils sont pratiques et simple d'utilisation.",
    },
    {
      name: "Site web Géo Etudes Plus",
      slug: "geo-etudes-plus",
      imgs: {
        thumbnail: "assets/img/projects/geo-etude-cover.PNG",
        cover: "assets/img/projects/geo-services.PNG",
        img1: "assets/img/projects/geo etude.PNG",
        img2: "assets/img/projects/geo-etude-cover.PNG",
      },
      customer: "Géo Etudes Plus",
      category: "Software, Géomatique",
      startDate: "Juin 2019",
      endDate: "Juillet 2019",
      status: "terminé",
      demos: {
        // android: 'goexpress-online.com',
        // ios: 'goexpress-online.com',
        web: "https://geo-plus-proj.firebaseapp.com/",
      },
      tags: "App, Web, Dev, Angular, PWA, Materialize Design, SIG, Webmapping, Leaflet, Cartographie",
      description: [""],
      customerQuote:
        "Un design moderne et une plateforme qui refléte bien notre idéologie.",
    },
    {
      name: "Plateformes de gestion des financements de l’ONUDI",
      slug: "onudi",
      imgs: {
        thumbnail: "assets/img/projects/onudi-cover.png",
        cover: "assets/img/projects/onudi-cover.png",
        img1: "assets/img/projects/ondi bd.png",
        img2: "assets/img/projects/onudi map.png",
      },
      customer: "ONUDI Sénégal",
      category: "Software",
      startDate: "Octobre 2018",
      endDate: "Décembre 2018",
      status: "terminé",
      demos: {
        // android: 'goexpress-online.com',
        // ios: 'goexpress-online.com',
        web: "Pas en ligne",
      },
      tags: "App, Web, Dev, BI, PHP, MySQL, SIG, Webmapping, Leaflet, Cartographie",
      description: [
        "Au plan général, il s’agira de mieux rendre visible le soutien apporté à la formation des jeunes de différentes filières et à leur insertion dans le tissu économique local et national, dans les zones couvertes par le projet par une actualisation à temps réel des données collectées. ",
        "•	Procéder au recensement des entreprises de jeunes établies dans la ZCO nord et centre ainsi que des jeunes ayant choisi l’emploi salarié ; ",
        "•	Collecter les informations et les indicateurs pertinents relatifs aux entreprises de jeunes et aux jeunes en emploi salarié ; ",
        "•	Concevoir l’outil de géolocalisation (90 jours) sur la base des données collectées et traitées en lien avec les caractères significatifs stabilisés, d’une part, pour les entreprises créées par les jeunes bénéficiaires du projet IDEA (date de création, financement reçu, type d’appuis reçus, chiffre d’affaires, nombre d’emplois crées, type d’entreprises : collective ou individuelle, classification en genre, etc.) et, d’autre part, pour les jeunes ayant choisi l’emploi salarié (date d’embauche, entreprises d’insertion, filières, postes occupés, etc.). ",
        "La réalisation du projet a permis aux usagers d’avoir une bonne compréhension du tissu des entreprises de jeunes suivant leurs caractéristiques significatives d’identification, de répartition et de contribution à l’économie locale et nationale, avec la construction de l’outil de géolocalisation des entreprises de jeunes. ",
      ],
      customerQuote:
        "Un travil professionel avec des résultats qui dépassent nos attentes. Très surpris de la qualité du travail par rapport à la durée.",
    },
    {
      name: "Etude Port-Ndayane",
      slug: "port-ndayane",
      imgs: {
        thumbnail: "assets/img/projects/ndayane1.PNG",
        cover: "assets/img/projects/ndayane-cover.PNG",
        img1: "assets/img/projects/ndayane2.PNG",
        img2: "assets/img/projects/ndayane3.PNG",
      },
      customer: "Clarize",
      category: "Géomatique, Software",
      startDate: "Octobre 2018",
      endDate: "Décembre 2018",
      status: "terminé",
      demos: {
        // android: 'goexpress-online.com',
        // ios: 'goexpress-online.com',
        web: "https://proj-ndayane.firebaseapp.com/",
      },
      tags: "App, Web, Dev, BI, SIG, Télédétection, Webmapping, Leaflet, Cartographie",
      description: [""],
      customerQuote:
        "La Géomatique pour la maitrise de l'impact du por de Ndayane.",
    },
    {
      name: "Outil de géomarketing",
      slug: "geomarketing",
      imgs: {
        thumbnail: "assets/img/partner/geo-marketing.png",
        cover:
          "https://www.bosch-thermotechnology.com/ocsmedia/optimized/1600x560/o362033v296_skv_Komponenten_Dampfkessel_1.jpg",
        img1: "assets/img/projects/petrosen valider commande.png",
        img2: "assets/img/projects/petrosen-dashboard.png",
      },
      customer: "Clarize",
      category: "Software, Géomatique",
      startDate: "Octobre 2018",
      endDate: "Décembre 2018",
      status: "terminé",
      demos: {
        // android: 'goexpress-online.com',
        // ios: 'goexpress-online.com',
        web: "https://geos-marketing.firebaseapp.com/",
      },
      tags: "App, Web, Dev, BI, SIG, Télédétection, Webmapping, Leaflet, Cartographie, Angular, Firebase",
      description: [""],
      customerQuote: "La Géomatique pour la maitrise de son emplacement.",
    },
    {
      name: "Résultats présidentiel 2019",
      slug: "presidentiel-2019",
      imgs: {
        thumbnail: "assets/img/projects/presidentielcover.PNG",
        cover: "assets/img/projects/presidentielcover.PNG",
        img1: "assets/img/projects/petrosen valider commande.png",
        img2: "assets/img/projects/petrosen-dashboard.png",
      },
      customer: "Clarize",
      category: "Software, Géomatique, BI",
      startDate: "Octobre 2018",
      endDate: "Décembre 2018",
      status: "terminé",
      demos: {
        // android: 'goexpress-online.com',
        // ios: 'goexpress-online.com',
        web: "https://presidentiel2019.github.io/home",
      },
      tags: "App, Web, Dev, BI, SIG, Webmapping, Leaflet, Cartographie",
      description: [""],
      customerQuote:
        "La Géomatique pour une diffusion spatiale de l'information.",
    },
    {
      name: "Site pour la jeunesse de Saint-Louis",
      slug: "srj-saint-louis",
      imgs: {
        thumbnail: "assets/img/projects/srjthumb.PNG",
        cover: "assets/img/projects/srjcover.PNG",
        img1: "assets/img/projects/srj1.PNG",
        img2: "assets/img/projects/srj2.PNG",
      },
      customer: "SRJ de Saint-Louis",
      category: "Software",
      startDate: "Octobre 2018",
      endDate: "Décembre 2018",
      status: "terminé",
      demos: {
        // android: 'goexpress-online.com',
        // ios: 'goexpress-online.com',
        web: "https://srjeunesse-sl.com",
      },
      tags: "App, Web, Dev, Wordpress, PHP, MySQL",
      description: [""],
      customerQuote: "Un design moderne, la balle est dans notre camp.",
    },
    {
      name: "Site Sorec SARL",
      slug: "sorec-site",
      imgs: {
        thumbnail: "assets/img/projects/sorec-thumb.PNG",
        cover: "assets/img/projects/sorec-cover.PNG",
        img1: "assets/img/projects/sorec1.PNG",
        img2: "assets/img/projects/sorec2.PNG",
      },
      customer: "SRJ de Saint-Louis",
      category: "Software",
      startDate: "Octobre 2018",
      endDate: "Décembre 2018",
      status: "terminé",
      demos: {
        // android: 'goexpress-online.com',
        // ios: 'goexpress-online.com',
        web: "https://sorec-site.firebaseapp.com/",
      },
      tags: "App, Web, Dev, Angular, Firebase, Material Design",
      description: [""],
      customerQuote: "Nous sommes honorés de la qualité du travail.",
    },
    {
      name: "Open Data Plateforme",
      slug: "open-data",
      imgs: {
        thumbnail: "assets/img/projects/opendatathumb.PNG",
        cover: "assets/img/projects/open-data-cover.PNG",
        img1: "assets/img/projects/opendata1.PNG",
        img2: "assets/img/projects/opendata2.PNG",
      },
      customer: "Clarize",
      category: "Software",
      startDate: "Octobre 2018",
      endDate: "Décembre 2018",
      status: "terminé",
      demos: {
        // android: 'goexpress-online.com',
        // ios: 'goexpress-online.com',
        web: "https://open-data-198e4.firebaseapp.com/",
      },
      tags: "App, Web, Dev, Angular, MongoDB, Realm, Material Design",
      description: [""],
      customerQuote:
        "Un outil simple pour une gestion efficace des Parcelles pour le Cadastre.",
    },
  ];

  constructor() {}

  getProject(slug: string) {
    return this.listProjects.slice().find((p) => p.slug === slug);
  }
}
