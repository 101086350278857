import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Service, ServicesService } from "src/app/services/services.service";

@Component({
  selector: "app-service-details",
  templateUrl: "./service-details.component.html",
  styleUrls: ["./service-details.component.scss"],
})
export class ServiceDetailsComponent implements OnInit {
  serviceDetails: Service;
  activeIndex: number | undefined;
  colors = [
    "purple",
    "red",
    "#07737f",
    "#303e66",
    "indigo",
    "orange",
    "#bf2a44",
    "#949420",
    "darkblue",
    "#298038",
    "#b58bd0",
  ];
  constructor(
    private servicesService: ServicesService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      // const params = this.route.snapshot.paramMap;
      const slug = params.get("slug");
      const active = +params.get("active");
      if (active) {
        this.activeIndex = active;
        setTimeout(() => {
          window.scrollTo(0, 1000);
        }, 1000);
      }
      this.serviceDetails = this.servicesService.getService(slug);
    });
  }

  switchActive(index: number) {
    this.activeIndex = this.activeIndex === index ? undefined : index;
  }
}
