<!-- Start Coming Soon Area -->
<section class="coming-soon-area">
  <div class="container-fluid p-0">
    <div class="row m-0">
      <div class="col-lg-6 col-md-12 p-0">
        <div class="coming-soon-time">
          <img src="assets/img/coming-soon-bg.jpg" alt="image" />
          <div id="timer">
            <div id="days">{{ days }} <span>Jours</span></div>
            <div id="hours">{{ hours }} <span>Heures</span></div>
            <div id="minutes">{{ minutes }} <span>Minutes</span></div>
            <div id="seconds">{{ seconds }} <span>Secondes</span></div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-12 p-0">
        <div class="coming-soon-content">
          <div class="d-table">
            <div class="d-table-cell">
              <div class="logo">
                <!-- <img src="assets/img/white-logo.png" alt="logo" /> -->
              </div>
              <h3>Le Clarize-Marketplace est en cours de développement</h3>
              <form>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="name"
                    id="name"
                    placeholder="Vôtre nom"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    id="email"
                    placeholder="Vôtre email adresse"
                  />
                </div>
                <button type="submit" class="btn btn-primary">Souscrire</button>
                <p>
                  Et ne vous inquiétez pas, nous détestons aussi les spams! Vous
                  pouvez vous désinscrire à tout moment temps.
                </p>
              </form>
              <div class="social">
                <ul>
                  <li>
                    <a
                      href="https://twitter.com/ClarizeGroup"
                      target="_blank"
                      class="twitter"
                      ><i class="fab fa-twitter"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UC-EvPSViTybbLY5tggTOBvw"
                      target="_blank"
                      class="youtube"
                      ><i class="fab fa-youtube"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://web.facebook.com/clarizegroup"
                      target="_blank"
                      class="facebook"
                      ><i class="fab fa-facebook-f"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/clarize-technology-group/"
                      target="_blank"
                      class="linkedin"
                      ><i class="fab fa-linkedin-in"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/clarizegroup/"
                      target="_blank"
                      class="instagram"
                      ><i class="fab fa-instagram"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Coming Soon Area -->
