<!-- Start Feedback Area -->
<section class="feedback-area">
  <div class="feedback-slides owl-carousel owl-theme">
    <div class="row m-0" *ngFor="let testimonial of testimonials">
      <div class="col-lg-6 col-md-12 p-0">
        <div class="feedback-item">
          <p>
            {{ testimonial.content }}
          </p>

          <div class="client-info">
            <div class="client-pic">
              <img src="{{ testimonial.photo }}" alt="image" />
            </div>
            <h3>{{ testimonial.name }}</h3>
            <span>{{ testimonial.title }}</span>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-12 p-0">
        <div
          class="client-image bg1"
          [ngStyle]="{ 'background-image': 'url(' + testimonial.cover + ')' }"
        >
          <!-- <img src="{{ testimonial.cover }}" alt="image" /> -->
        </div>
      </div>
    </div>

    <!-- <div class="row m-0">
      <div class="col-lg-6 col-md-12 p-0">
        <div class="feedback-item">
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humor predefined words which don't look.
          </p>

          <div class="client-info">
            <div class="client-pic">
              <img src="assets/img/client/client2.png" alt="image" />
            </div>
            <h3>Sarah Taylor</h3>
            <span>Designer</span>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-12 p-0">
        <div class="client-image bg2">
          <img src="assets/img/client/client2.png" alt="image" />
        </div>
      </div>
    </div>

    <div class="row m-0">
      <div class="col-lg-6 col-md-12 p-0">
        <div class="feedback-item">
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humor predefined words which don't look.
          </p>

          <div class="client-info">
            <div class="client-pic">
              <img src="assets/img/client/client3.jpg" alt="image" />
            </div>
            <h3>Meg Lanning</h3>
            <span>Support</span>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-12 p-0">
        <div class="client-image bg3">
          <img src="assets/img/client/client3.jpg" alt="image" />
        </div>
      </div>
    </div> -->
  </div>
</section>
<!-- End Feedback Area -->
