import { Injectable, Component, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";

interface NotificationData {
  type: "success" | "warning" | "error";
  icon: string;
  message: string;
}

@Component({
  selector: "app-flash-notification",
  template: `
    <div class="white-text center-content">
      <span>
        <mat-icon aria-label="">{{ data.icon }}</mat-icon>
      </span>
      <span>
        {{ data.message }}
      </span>
    </div>
  `,
  styles: [
    `
      mat-icon {
        position: relative;
        top: 0.25rem;
        padding-right: 2rem;
      }
    `,
  ],
})
export class FlashNotificationComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: NotificationData) {}
}

@Injectable({
  providedIn: "root",
})
export class NotifierService {
  // saveErrorOnCloud(error: any) {
  //   throw new Error('Method not implemented.');
  // }
  constructor(private snackBar: MatSnackBar, private dialog: MatDialog) {}
  successNofication(message: string) {
    const notificationData = {
      type: "success",
      icon: "offline_pin",
      message,
    };
    this.sendNotificationToUser(notificationData, "green");
  }

  errorNofication(message: string) {
    const notificationData = {
      type: "error",
      icon: "feedback",
      message,
    };
    this.sendNotificationToUser(notificationData, "red");
  }

  warningNofication(message: string) {
    const notificationData = {
      type: "warning",
      icon: "report_problem",
      message,
    };
    this.sendNotificationToUser(notificationData, "amber");
  }

  private sendNotificationToUser(data, background) {
    this.snackBar.openFromComponent(FlashNotificationComponent, {
      duration: 5000,
      data,
      panelClass: background,
    });
  }

  notifyMe(text: string) {
    // Vérifions si le navigateur prend en charge les notifications
    if (!("Notification" in window)) {
      alert("Ce navigateur ne prend pas en charge la notification de bureau");
    }

    // Vérifions si les autorisations de notification ont déjà été accordées
    else if (Notification.permission === "granted") {
      // Si tout va bien, créons une notification
      const notification = new Notification(text);
    }

    // Sinon, nous devons demander la permission à l'utilisateur
    else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        // Si l'utilisateur accepte, créons une notification
        if (permission === "granted") {
          const notification = new Notification(text);
        }
      });
    }
  }
}
