import { Injectable } from "@angular/core";

export interface Service {
  name: string;
  slug: string;
  imgs: {
    cover: string;
    img1: string;
    img2: string;
  };
  categories: {
    description: string[];
    listCateg: {
      name: string;
      description: string;
      listTechnologie: string[];
    }[];
  };
}
@Injectable({
  providedIn: "root",
})
export class ServicesService {
  services: Service[] = [
    {
      name: "Software Engineers",
      slug: "software-engineers",
      imgs: {
        cover: "assets/img/services/Software-Engineer.jpg",
        img1: "assets/img/services/se-1.jpg",
        img2: "assets/img/services/software-development.png",
      },
      categories: {
        description: [
          "Clarize vous accompagne dans le développement de compétences communes en architecture de systèmes informatiques et en conception de logiciels. ",

          "L'ingénieur logiciel doit  maîtriser la théorie et les méthodes de l'informatique. Si les ingénieurs logiciels possèdent un vaste bagage en matériel informatique, ils se spécialisent dans la conception, la maintenance et le développement des systèmes et des produits logiciels.",

          "Le développement logiciel désigne l’ensemble du processus consistant à bâtir tout type d’applications informatiques fiables et performantes et va de l’étude du besoin du client, en passant par la conception, la mise en œuvre jusqu’à la maintenance de l’application. Ces diverses étapes sont possibles grâce à un langage informatique spécifique ou plutôt grâce à plusieurs langages… et à des développeurs maitrisant ces langages.",
        ],
        listCateg: [
          {
            name: "Développement Web/PWA",
            description:
              "Le web évolue en permanence à travers de nouvelles fonctionnalités toujours plus innovantes. On peut maintenant faire d’un site web une application qui utilise les dernières technologies des smartphones.",
            listTechnologie: [
              "Javascript",
              "Angular",
              "ReactJs",
              "VueJs",
              "NodeJs",
              "Deno",
              "Laravel",
              "Django",
              "Wordpress",
              "Drupal",
              "Woocommerce",
            ],
          },
          {
            name: "Desktop: Windows, Mac, Linux",
            description:
              "Certains défis technologiques exigent les performances d’une application native, conçue pour vivre en symbiose avec son système d’exploitation et sa plateforme matérielle. ",
            listTechnologie: ["Cordava", "ElectronJs"],
          },
          {
            name: "Mobile: Andoid, IOS, Hybrid",
            description:
              "Le marché du mobile connaît une extension folle ces dernières années. Clarize vous accompagne sur la conception et la réalisation de vos projets de dev mobiles natives et hybrides.",
            listTechnologie: [
              "NativeScript",
              "React Native",
              "Flutter",
              "Ionic",
              "Android Studio",
              "Xcode",
            ],
          },
          {
            name: "Base de données",
            description:
              "Conception et création de base de données (SQL, NoSQL, ..",
            listTechnologie: [
              "MongoDB",
              "Firestore",
              "FuniaDB",
              "MySQL",
              "Postgres SQL",
              "Casandra",
              "Neo4J",
            ],
          },
          {
            name: "UI/UX Design",
            description:
              "L’avancée très rapide des technologies liées au Web engendre de nouvelles qualifications du métier de web designer notamment sur tablettes, smartphones et objets connectés. Cependant Clarize investit beaucoup dans ce domaine pour la réalisation de solutions attrayantes.",
            listTechnologie: [
              "Figma",
              "Adobe Xd",
              "Adobe Illustrator",
              "PhotoShop",
              "Canvas",
            ],
          },
        ],
      },
    },
    {
      name: "Surveillance & Security",
      slug: "security-surveillance",
      imgs: {
        cover: "assets/img/services/security-cover.jpg",
        img1: "assets/img/services/security.jpg",
        img2: "assets/img/services/security2.jpg",
      },
      categories: {
        description: [
          "Clarize vous accompagne sur la protection de vos données, sur le monotoring de vos infrastructures, ... ",
          "La sécurité des systèmes d’information (SSI) ou plus simplement sécurité informatique, est l’ensemble des moyens techniques, organisationnels, juridiques et humains nécessaires à la mise en place de moyens visant à empêcher l'utilisation non autorisée, le mauvais usage, la modification ou le détournement du système d'information. Assurer la sécurité du système d'information est une activité du management du système d'information.",
          "Elle vise les objectifs suivants : la confidentialité, l'authenticité, l'intégrité, la disponibilité, la traçabilité, La non-répudiation et l'imputation de vos données et de vos systèmes.",
        ],
        listCateg: [
          {
            name: "Data access protection",
            description:
              "Votre entreprise doit réduire les coûts et innover pour préserver sa compétitivité, mais elle doit également suivre l'évolution des exigences en matière de sécurité et de conformité réglementaire dans un environnement technologique toujours plus distribué et complexe. Pour y parvenir, vous devez adopter un programme de sécurité qui inclut une fonction de surveillance continue. ",
            listTechnologie: [],
          },
          {
            name: "APIs Protection",
            description:
              "Comme la plupart des personnes, vous préférez sûrement placer vos économies dans un lieu sûr (une banque, par exemple) plutôt que sous votre matelas, et vous utilisez également différentes méthodes pour autoriser et authentifier vos paiements. Il en va de même pour la sécurité des API. Vous avez besoin d'un environnement fiable qui applique des politiques d'authentification et d'autorisation.",
            listTechnologie: [],
          },
          {
            name: "Cloud monotoring",
            description:
              "Les environnements de cloud hybride offrent aux utilisateurs de nombreux choix et un haut niveau de flexibilité. C'est un moyen de préserver les données sensibles ou critiques hors du cloud public tout en tirant parti de ce dernier pour les données qui ne présentent pas le même niveau de risques.",
            listTechnologie: [],
          },
          {
            name: "Crytographie",
            description: "",
            listTechnologie: [],
          },
        ],
      },
    },
    {
      name: "Cloud et IOT",
      slug: "iot-cloud",
      imgs: {
        cover: "assets/img/services/cloud.jpg",
        img1: "assets/img/services/iot-cloud-platforms.jpg",
        img2: "assets/img/services/cloud-iot.jpg",
      },
      categories: {
        description: [
          "Les entreprises les plus performantes ont placé le Cloud au coeur de leur transformation digitale. En ayant des fondations solides sur le développement et la mise en place de solutions CLOUD et IOT robustes et performantes, Clarize vous offre des solutions pour faciliter vos opérations de digitalisation, de virtualisation, et de conteneurisation de vos taches, systèmes, applications, infrastructures...",

          "Pour faciliter les échanges de données entre les objets connectés et les systèmes qui traitent les informations qui sont remontées par leur biais ou qui leur donnent des instructions à réaliser, Clarize vous propose des plateformes de management de ces données, des APIs pour accéder ces données via d'autres applications, des solutions WEB et AI sur vos opérations de Data minig, Data Visualisation, Bid Data, ...",
        ],
        listCateg: [
          {
            name: "Transformation Digitale",
            description:
              "La transformation digitale, que l’on appelle parfois aussi transformation numérique, désigne le processus qui permet aux entreprises d’intégrer toutes les technologies digitales disponibles au sein de leurs activités, le passage du manuel, anologique au numérique...",
            listTechnologie: [
              "Web",
              "Automation",
              "Virtualisation",
              "Big Data",
              "AI",
              "IOT",
              "Mobile",
            ],
          },
          {
            name: "Virtualisation",
            description:
              "La virtualisation est une technologie qui vous permet de créer plusieurs environnements simulés ou ressources dédiées à partir d'un seul système physique.",
            listTechnologie: [
              "Cloud computing",
              "Cloud Database",
              "Docker",
              "VmWares",
              "Virtual Networking",
              "Remote Servers",
            ],
          },
          {
            name: "Cloud Computing",
            description:
              "Le Cloud Computing est un terme général employé pour désigner la livraison de ressources et de services à la demande par internet.",
            listTechnologie: [
              "GCP",
              "AWS",
              "Azure",
              "Salesforce",
              "IBM Cloud",
              "Oracle Cloud",
              "Linode",
              "Netlify",
              "Alibaba Cloud",
            ],
          },
          {
            name: "IOT",
            description:
              "Clarize vous propulse plus loin avec des solutions IOT en effectuant l'analyse des données sur une plateforme cloud avec des modèles de machine learning pour une exploitation rapide et précise.",
            listTechnologie: [
              "Smart objects",
              "Automotive",
              "Google Cloud IoT",
              "Microsoft Azure IoT Hub",
              "Amazon Web Services IoT",
              "Arduino",
              "Raspberry Pi",
            ],
          },
          // {
          //   name: "UI/UX Design",
          //   description: "",
          //   listTechnologie: [
          //     "Figma",
          //     "Adobe Xd",
          //     "Adobe Illustrator",
          //     "PhotoShop",
          //     "Canvas",
          //   ],
          // },
        ],
      },
    },
    {
      name: "Géomatique",
      slug: "geomatique",
      imgs: {
        cover: "assets/img/services/geomatique.jpg",
        img1: "assets/img/services/geomatique1.jpg",
        img2: "assets/img/services/geomatique2.png",
      },
      categories: {
        description: [
          "Clarize offre des solutions géomatique dans de nombreux domaines tels que l'aménagement du territoire, la prévention des risques naturels ou encore la gestion des ressources naturelles et de l'urbanisme. Cependant, l'utilisation de la géomatique se généralise de plus en plus dans les secteurs de l'économie et du marketing. En effet, la représentation spatiale des données facilite les études de marché et donc l'identification des tendances ou l'analyse de la concurrence dans des secteurs géographiques donnés.",

          "Clarize offre des solutions complètes qui exploitent la dimension géographique afin de résoudre les challenges d'aujourd'hui.",
        ],
        listCateg: [
          {
            name: "SIG",
            description:
              "Les SIG tiennent une place centrale dans la géomatique puisqu'ils sont les outils informatiques permettant la représentation et l'analyse des données. De plus, près de 80% des données en entreprise ont un caractère géographique (adresse des clients et des fournisseurs, coordonnées des points de vente, réseau....).",
            listTechnologie: ["ArcGIS", "QGIS", "Webmapping"],
          },
          {
            name: "Cartographie",
            description:
              "Les cartes permettent de communiquer et de transmettre de grandes quantités d'informations d'une façon organisée et compréhensible",
            listTechnologie: ["ArcGIS", "QGIS", "Webmapping"],
          },
          {
            name: "Photogrammétrie",
            description:
              "La photogrammétrie aérienne est l’ensemble des techniques et des matériels utilisés pour aboutir à la représentation d’un territoire étendu, à partir des clichés de la prise de vues aériennes.",
            listTechnologie: [
              "Pixel 4D",
              "Cinema 4D",
              "Erdas Imagine",
              "Avisoft",
              "Photoshop",
              "Drone",
            ],
          },
          {
            name: "Télédétection",
            description:
              "Pour faire des études complexes sur de vastes étendues de surface grace à la puissance du Soleil et des satellites d'observation terreste, Clarize vous accompagne dans vos exploitations agricoles, vos projets d'aménagement de territoire, gestions minières, ...",
            listTechnologie: ["Erdas Imagine", "QGIS", "Envi"],
          },
          {
            name: "Agriculture de Précision",
            description:
              "Clarize utilise les nouvelles technologies, comme la robotique, l’IA et les objets connectés, les photographiess aériennes avec la puissance du cloud computing, afin d’améliorer les performances des exploitations agricoles et migrer vers une agriculture de précisions.",
            listTechnologie: [
              "Erdas Imagine",
              "Envi",
              "Drone",
              "Webmapping",
              "IOT",
              "Cloud",
              "Prédiction",
            ],
          },
          {
            name: "Web-mapping",
            description:
              "Le webmapping est la forme de la cartographie numérique qui fait usage d'Internet pour pouvoir produire, concevoir, traiter et publier des cartes géographiques. C'est un domaine en pleine expansion grâce au développement des solutions Open Source.",
            listTechnologie: [
              "LeafletJs",
              "Mapbox",
              "ArcGIS online",
              "Google Map",
              "UMap",
              "QGIS",
            ],
          },
        ],
      },
    },
    {
      name: "AI et business intelligence",
      slug: "data-analysts",
      imgs: {
        cover: "assets/img/services/bi-cover.jpg",
        img1: "assets/img/services/bi.png",
        img2: "assets/img/services/bi2.jpg",
      },
      categories: {
        description: [
          "La Business Intelligence (ou BI) est appelée en français « informatique décisionnelle ». Il s’agit de solutions centrées sur les données de l’entreprise qui offrent aux métiers une aide à la décision grâce à des tableaux de bord, suivis d’activité ou rapports analytiques.",

          "Le Machine Learning se base donc sur les statistiques, l’analyse mais aussi la reconnaissance de patterns (motifs). On enseigne, par le biais d’un ensemble de technologies, à une machine à apprendre puis à exécuter des tâches de façon autonome. C’est ce qu’on appelle “automatisation de processus” : la machine se charge des tâches qui sont souvent répétitives de façon autonome, sans besoins d’intervention humaine.",
          "La business intelligence aide donc à poser les bases pour aller plus loin. Elle est en quelque sorte le premier palier vers la capitalisation de vos données. Mais alors pourquoi ne pas sauter cette étape et directement se tourner vers l’IA ? Car la BI est un sujet « métier » et non à proprement technique comme l’est le Machine Learning.",
        ],
        listCateg: [
          {
            name: "Machine Learning",
            description:
              "Le Machine Learning peut être défini comme une branche de l’intelligence artificielle englobant de nombreuses méthodes permettant de créer automatiquement des modèles à partir des données. Clarize vous accompagne sur vos opérations de data minig, data exploration pour vous permettre de prendre les meilleurs décisions.",
            listTechnologie: [
              "Deep Learning",
              "Classification",
              "Regression Linéaire",
              "SVM",
              "KMeans",
              "TensorFlow",
              "Pytorch",
              "Keras",
              "Skit-Learn",
              "RNN",
              "LSTM",
              "RL",
              "Chatbots",
              "Python",
              "Javascript",
            ],
          },
          {
            name: "BI",
            description:
              "Elle englobe l’ensemble des méthodes de collecte, de traitement et de visualisation des données de l’entreprise afin d’offrir une vision et du recul aux décideurs sur leurs activités. ",
            listTechnologie: [
              "BI",
              "Data visualisation",
              "ERP",
              "Statistique",
              "Data Mining",
              "Data Mining",
              "Power BI",
              "Big Data",
              "ETL",
            ],
          },
          {
            name: "Data visualisation",
            description:
              "La “dataviz” vient mettre un terme à cette tâche fastidieuse. Elle consiste à communiquer des chiffres ou des informations brutes en les transformant en objets visuels : points, barres, courbes, cartographies. C’est une bonne représentation de data storytelling. Elle raconte visuellement l’évolution en la comparant aux différents pays, catégories d’âge et années. La visualisation est accompagnée de commentaires courts afin guider l’audience. C’est un moyen de communication puissant.",
            listTechnologie: [
              "Big query",
              "Tableau",
              "D3.js",
              "Plotly",
              "Power BI",
              "Excel",
            ],
          },
          {
            name: "Automation",
            description:
              "L’automatisation numérique des opérations est devenue un chantier stratégique pour les entreprises car elle conditionne leur niveau de performance opérationnelle et le succès de leur transformation digitale..",
            listTechnologie: [
              "Python",
              "Microsoft Power Automate",
              "Automate RPA",
              "ElectroNeek Studio IDE",
              "Automate.io",
            ],
          },
        ],
      },
    },
  ];
  constructor() {}

  getService(slug: string) {
    return this.services.slice().find((p) => p.slug === slug);
  }
}
