import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Project, ProjectsService } from "src/app/services/projects.service";

@Component({
  selector: "app-project-details",
  templateUrl: "./project-details.component.html",
  styleUrls: ["./project-details.component.scss"],
})
export class ProjectDetailsComponent implements OnInit {
  project: Project;
  colors = [
    "purple",
    "red",
    "#07737f",
    "#303e66",
    "indigo",
    "orange",
    "#bf2a44",
    "#949420",
    "darkblue",
    "#298038",
    "#b58bd0",
  ];
  constructor(
    private projectsService: ProjectsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const slug = this.route.snapshot.paramMap.get("slug");
    this.project = this.projectsService.getProject(slug);
  }
}
