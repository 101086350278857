<section class="team-area ptb-110">
  <div class="container">
    <div class="section-title">
      <h2>Des professionnels à vos services</h2>
      <p>Des efforts fournis et unis, pour vous accompagner</p>
    </div>

    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-team-box">
          <div class="image">
            <img
              src="assets/img/team/sidy.jpg"
              alt="Sidy Mbengue Clarize Group"
            />
            <div class="social">
              <a
                href="https://www.linkedin.com/in/sidy-mbengue/"
                target="_blank"
                ><i class="fab fa-linkedin-in"></i
              ></a>
             <!-- <a href="https://web.facebook.com/sidyakon/" target="_blank"
                ><i class="fab fa-facebook-f"></i
              ></a>
              <a href="https://twitter.com/mbengue_sidy" target="_blank"
                ><i class="fab fa-twitter"></i
              ></a>
              <a href="https://www.instagram.com/mbenguesidy/" target="_blank"
                ><i class="fab fa-instagram"></i
              ></a>-->
            </div>
          </div>
          <div class="content">
            <h3>Sidy MBENGUE</h3>
            <span>CEO/Founder</span>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-team-box">
          <div class="image">
            <img
              src="assets/img/team/raby-sene.jpg"
              alt="Raby Séne Clarize Group"
            />
            <div class="social">
              <a
                href="https://www.linkedin.com/in/raby-sene-206846138/"
                target="_blank"
                ><i class="fab fa-linkedin-in"></i
              ></a>
             <!-- <a
                href="https://web.facebook.com/Rabyvictoire.sene"
                target="_blank"
                ><i class="fab fa-facebook-f"></i
              ></a>
              <a href="https://twitter.com/SeneRaby" target="_blank"
                ><i class="fab fa-twitter"></i
              ></a>
              <a href="https://www.instagram.com/rabyv_sene/" target="_blank"
                ><i class="fab fa-instagram"></i
              ></a>-->
            </div>
          </div>
          <div class="content">
            <h3>Raby SENE</h3>
            <span>CTO</span>
          </div>
        </div>
      </div>

      <!--<div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-team-box">
          <div class="image">
            <img
              src="assets/img/team/gueye.jpg"
              alt="Mouhammadane Gueye Clarize Group"
            />
             <div class="social">
              <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
              <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
              <a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a>
          </div>
          <div class="content">
            <h3>Mouhammadane Gueye</h3>
            <span>IOT & Hardware developer</span>
          </div>
        </div>
      </div>-->
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-team-box">
          <div class="image">
            <img
              src="assets/img/team/Ba.jpg"
              alt="Ibrahima Ba Clarize Group"
            />
            <div class="social">
              <a
                href="https://www.linkedin.com/in/ibrahima-ba-85064387/"
                target="_blank"
                ><i class="fab fa-linkedin-in"></i
              ></a>
              <!--<a href="https://web.facebook.com/ithiou" target="_blank"
                ><i class="fab fa-facebook-f"></i
              ></a>
              <a href="https://twitter.com/ithiou" target="_blank"
                ><i class="fab fa-twitter"></i
              ></a>
              <a href="https://www.instagram.com/ithiou/" target="_blank"
                ><i class="fab fa-instagram"></i
              ></a>-->
            </div>
          </div>
          <div class="content">
            <h3>Ibrahima BA</h3>
            <span>CSO/CMO</span>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-team-box">
          <div class="image">
            <img
              src="assets/img/team/Baye.jpeg"
              alt="Baye Sény Déme Clarize Group"
            />
            <div class="social">
              <a
                href="https://www.linkedin.com/in/seny-deme-005445158/"
                target="_blank"
                ><i class="fab fa-linkedin-in"></i
              ></a>
              <!--<a href="https://web.facebook.com/seni.deme" target="_blank"
                ><i class="fab fa-facebook-f"></i
              ></a>
              <a href="https://twitter.com/DemeSeny" target="_blank"
                ><i class="fab fa-twitter"></i
              ></a>
              <a href="https://www.instagram.com/demeseny/" target="_blank"
                ><i class="fab fa-instagram"></i
              ></a>-->
            </div>
          </div>
          <div class="content">
            <h3>Baye Sény DEME</h3>
            <span>COO/CFO</span>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-team-box">
          <div class="image">
            <img
              src="assets/img/team/awa.png"
              alt="Awa BALDE Clarize Group"
            />
            <div class="social">
              <a
                href="https://www.linkedin.com/in/awa-balde-8b0b50174?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                target="_blank"
                ><i class="fab fa-linkedin-in"></i
              ></a>
             <!-- <a
                href="https://web.facebook.com/profile.php?id=100005904467171"
                target="_blank"
                ><i class="fab fa-facebook-f"></i -->
             <!-- ></a>-->
              <!-- <a href="#" target="_blank"><i class="fab fa-twitter"></i></a> -->
            </div>
          </div>
          <div class="content">
            <h3>Awa BALDE</h3>
            <span>Responsable communication/Community manager</span>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-team-box">
          <div class="image">
            <img
              src="assets/img/team/Deme.jpeg"
              alt="Mamour Houma Deme Clarize Group"
            />
            <div class="social">
               <a
                href="https://www.linkedin.com/in/mamour-deme-16331b1b2?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                target="_blank"
                ><i class="fab fa-linkedin-in"></i
              ></a> 
              <!--<a
                href="https://web.facebook.com/serignembacke.syll.7"
                target="_blank"
                ><i class="fab fa-facebook-f"></i
              ></a>-->
              <!-- <a href="#" target="_blank"><i class="fab fa-twitter"></i></a> -->
            </div>
          </div>
          <div class="content">
            <h3>Mamour Houma DEME</h3>
            <span>Chef de produit/Devops</span>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-team-box">
          <div class="image">
            <img
              src="assets/img/team/seck.jpg"
              alt="Ndiaye SECK Clarize Group"
            />
            <div class="social">
              <!-- <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
              <a href="#" target="_blank"><i class="fab fa-twitter"></i></a> -->
              <a
                href="https://www.linkedin.com/in/mamadou-ndiaye-seck-104439142?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
                target="_blank"
                ><i class="fab fa-linkedin-in"></i
              ></a>
            </div>
          </div>
          <div class="content">
            <h3>Mamadou Ndiaye SECK</h3>
            <span>Web SIG manager</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
