<!-- Start Page Title Area -->
<div *ngIf="project" class="page-title-area desc-project">
  <div class="container">
    <div class="page-title-content">
      <h2>{{ project?.name }}</h2>
      <ul>
        <li><a routerLink="/">Accueil</a></li>
        <li><a routerLink="/projects">Projets</a></li>
        <li>{{ project?.slug }}</li>
      </ul>
    </div>
  </div>

  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
</div>
<!-- End Page Title Area -->

<!-- Start project Details Area -->
<div class="projects-details-area ptb-50">
  <div class="container">
    <div class="projects-details">
      <h1>Présentation et quelques captures</h1>
      <div class="row">
        <!-- [ngStyle]="{
          background:
            'linear-gradient(to bottom right, #082cf98c, #07737fa1), url(' +
            project?.imgs.cover +
            ');',
          backgroundImage: 'url(' + project?.imgs.cover + ')'
        }" -->
        <div class="col-lg-4 col-md-4 col-sm-12 m-y-2">
          <div class="projects-details-info">
            <div class="d-table">
              <div class="d-table-cell">
                <ul>
                  <li><span>Client : </span> {{ project?.customer }}</li>
                  <li><span>Catégorie : </span> {{ project?.category }}</li>
                  <li>
                    <span>Date de début : </span> {{ project?.startDate }}
                  </li>
                  <li>
                    <span>Date de livraison : </span> {{ project?.endDate }}
                  </li>
                  <li><span>Status : </span> {{ project?.status }}</li>
                  <li>
                    <span>Lien web : </span>
                    <a href="{{ project?.demos.web }}" target="_blank">{{
                      project?.demos.web
                    }}</a>
                  </li>
                  <li *ngIf="project?.demos.android">
                    <span>Application Android : </span>
                    <a href="{{ project?.demos.android }}" target="_blank">{{
                      project?.demos.android
                    }}</a>
                  </li>
                  <li *ngIf="project?.demos.ios">
                    <span>Application IOS : </span>
                    <a href="{{ project?.demos.ios }}" target="_blank">{{
                      project?.demos.ios
                    }}</a>
                  </li>
                  <li>
                    <span>Tags : </span>
                    <button
                      class="btn tag"
                      *ngFor="
                        let tag of project?.tags.split(', ');
                        let i = index
                      "
                      [ngStyle]="{ background: colors[i] }"
                    >
                      {{ tag }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 m-y-2">
          <div class="projects-details-image">
            <input type="checkbox" id="zoomCheck" />
            <label for="zoomCheck">
              <img src="{{ project?.imgs?.img1 }}" [alt]="project.name" />
            </label>
          </div>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-12 m-y-2">
          <div class="projects-details-image">
            <input type="checkbox" id="zoomCheck2" />
            <label for="zoomCheck2">
              <img src="{{ project?.imgs?.img2 }}" [alt]="project.name" />
            </label>
          </div>
        </div>

        <div *ngIf="project" class="col-lg-12 col-md-12 col-sm-12">
          <input type="checkbox" id="zoomCheck0" />
          <label for="zoomCheck0">
            <img src="{{ project?.imgs.cover }}" [alt]="project.name" />
          </label>
        </div>
      </div>

      <div class="projects-details-desc">
        <h3>Description</h3>
        <p *ngFor="let desc of project.description">
          {{ desc }}
        </p>
        <blockquote class="wp-block-quote">
          <p class="mb-0">
            {{ project.customerQuote }}
          </p>
        </blockquote>
        <!-- <p>
          Lorem ipsum dolor sit amet, conse cte tuer adipiscing elit, sed diam
          no nu m nibhie eui smod. Facil isis atve eros et accumsan etiu sto odi
          dignis sim qui blandit praesen lup ta de er. At molestiae appellantur
          pro. Vis wisi oportere per ic ula ad, ei latine prop riae na, mea cu
          purto debitis.
        </p>
        <p>
          Nost rud no eos, no impedit dissenti as mea, ea vide labor amus
          neglegentur vix. Ancillae intellegat vix et. Sit causae laoreet nolu
          ise. Ad po exerci nusquam eos te. Cu altera expet enda qui, munere
          oblique theo phrastu ea vix. Ne nec modus civibus modera tius, sit ei
          lorem doctus. Ne docen di verterem reformidans eos. Cu altera
          expetenda qui, munere oblique theophr astus ea vix modus civiu mod
          eratius.
        </p> -->
      </div>
    </div>
  </div>
</div>
<!-- End project Details Area -->
