<!-- Start Navbar Area -->
<div class="{{ navClass }}" id="top">
  <div class="kirin-nav">
    <div class="{{ navContainer }}">
      <nav class="navbar navbar-expand-md navbar-light">
        <a class="navbar-brand" routerLink="/">
          <img src="{{ logo }}" class="main-logo" alt="logo" />
          <img
            src="assets/img/white-logo.png"
            class="optional-logo"
            alt="logo"
          />
        </a>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div
          class="collapse navbar-collapse mean-menu"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav">
            <li
              class="nav-item"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            >
              <a routerLinkActive="active" routerLink="/home" class="nav-link">
                <!-- data-toggle="collapse"
                data-target=".navbar-collapse.show" -->
                Accueil
                <!-- <i class="fas fa-chevron-down"></i> -->
              </a>
              <!-- <ul class="dropdown-menu">
                                <li
                                    class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show"
                                >
                                    <a
                                        routerLink="/"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        class="nav-link"
                                    >
                                        Home One
                                    </a>
                                    <a
                                        routerLink="/machine-learning"
                                        routerLinkActive="active"
                                        class="nav-link"
                                    >
                                        Home Two
                                    </a>

                                    <a
                                        routerLink="/machine-learning-2"
                                        routerLinkActive="active"
                                        class="nav-link"
                                    >
                                        Home Three
                                    </a>
                                </li>
                            </ul> -->
            </li>

            <li
              class="nav-item"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            >
              <a routerLink="/about" routerLinkActive="active" class="nav-link"
                >Qui sommes-nous</a
              >
            </li>

            <!-- <li class="nav-item dropdown pages-dropdown">
                            <a

                                class="nav-link dropdown-toggle"
                                data-toggle="dropdown"

                            >
                                Pages <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li
                                    class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show"
                                >
                                    <a
                                        routerLink="/about"
                                        routerLinkActive="active"
                                        class="nav-link"
                                        >About Us</a
                                    >
                                </li>

                                <li
                                    class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show"
                                >
                                    <a
                                        routerLink="/team"
                                        routerLinkActive="active"
                                        class="nav-link"
                                        >Team</a
                                    >
                                </li>

                                <li
                                    class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show"
                                >
                                    <a
                                        routerLink="/pricing"
                                        routerLinkActive="active"
                                        class="nav-link"
                                        >Pricing</a
                                    >
                                </li>

                                <li class="nav-item dropdown-submenu">
                                    <a

                                        class="nav-link"
                                    >
                                        Services
                                        <i class="fas fa-chevron-down"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li
                                            class="nav-item"
                                            data-toggle="collapse"
                                            data-target=".navbar-collapse.show"
                                        >
                                            <a
                                                routerLink="/services"
                                                routerLinkActive="active"
                                                class="nav-link"
                                                >Services</a
                                            >
                                        </li>

                                        <li
                                            class="nav-item"
                                            data-toggle="collapse"
                                            data-target=".navbar-collapse.show"
                                        >
                                            <a
                                                routerLink="/service-details"
                                                routerLinkActive="active"
                                                class="nav-link"
                                                >Service Details</a
                                            >
                                        </li>
                                    </ul>
                                </li>

                                <li class="nav-item dropdown-submenu">
                                    <a

                                        class="nav-link"
                                    >
                                        Blog <i class="fas fa-chevron-down"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li
                                            class="nav-item"
                                            data-toggle="collapse"
                                            data-target=".navbar-collapse.show"
                                        >
                                            <a
                                                routerLink="/blog"
                                                routerLinkActive="active"
                                                class="nav-link"
                                                >Blog</a
                                            >
                                        </li>

                                        <li
                                            class="nav-item"
                                            data-toggle="collapse"
                                            data-target=".navbar-collapse.show"
                                        >
                                            <a
                                                routerLink="/blog-details"
                                                routerLinkActive="active"
                                                class="nav-link"
                                                >Blog Details</a
                                            >
                                        </li>
                                    </ul>
                                </li>

                                <li
                                    class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show"
                                >
                                    <a
                                        routerLink="/partner"
                                        routerLinkActive="active"
                                        class="nav-link"
                                        >Partner</a
                                    >
                                </li>

                                <li
                                    class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show"
                                >
                                    <a
                                        routerLink="/not-found"
                                        routerLinkActive="active"
                                        class="nav-link"
                                        >404 Error</a
                                    >
                                </li>

                                <li
                                    class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show"
                                >
                                    <a
                                        routerLink="/coming-soon"
                                        routerLinkActive="active"
                                        class="nav-link"
                                        >Coming Soon</a
                                    >
                                </li>

                                <li
                                    class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show"
                                >
                                    <a
                                        routerLink="/faq"
                                        routerLinkActive="active"
                                        class="nav-link"
                                        >FAQ</a
                                    >
                                </li>
                            </ul>
                        </li> -->

            <li
              class="nav-item"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            >
              <a
                class="nav-link"
                routerLink="/services"
                routerLinkActive="active"
              >
                <!-- data-toggle="dropdown" -->
                Nos Services
                <!-- <i class="fas fa-chevron-down"></i> -->
              </a>
              <!-- <ul class="dropdown-menu">
                                <li
                                    class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show"
                                >
                                    <a
                                        routerLink="/services"
                                        routerLinkActive="active"
                                        class="nav-link"
                                        >Services</a
                                    >
                                </li>

                                <li
                                    class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show"
                                >
                                    <a
                                        routerLink="/service-details"
                                        routerLinkActive="active"
                                        class="nav-link"
                                        >Services Details</a
                                    >
                                </li>
                            </ul> -->
            </li>

            <li
              class="nav-item"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            >
              <a
                class="nav-link"
                routerLink="/projects"
                routerLinkActive="active"
              >
                Nos Projets
                <!-- <i class="fas fa-chevron-down"></i> -->
              </a>
              <!-- <ul class="dropdown-menu">
                <li
                  class="nav-item"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  <a
                    routerLink="/projects"
                    routerLinkActive="active"
                    class="nav-link"
                    >Projects</a
                  >
                </li>

                <li
                  class="nav-item"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  <a
                    routerLink="/project-details"
                    routerLinkActive="active"
                    class="nav-link"
                    >Project Details</a
                  >
                </li>
              </ul> -->
            </li>

            <!-- <li class="nav-item dropdown">
                            <a

                                class="nav-link dropdown-toggle"
                                data-toggle="dropdown"

                            >
                                Blog <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li
                                    class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show"
                                >
                                    <a
                                        routerLink="/blog"
                                        routerLinkActive="active"
                                        class="nav-link"
                                        >Blog</a
                                    >
                                </li>

                                <li
                                    class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show"
                                >
                                    <a
                                        routerLink="/blog-details"
                                        routerLinkActive="active"
                                        class="nav-link"
                                        >Blog Details</a
                                    >
                                </li>
                            </ul>
                        </li> -->

            <!-- <li
                            class="nav-item"
                            data-toggle="collapse"
                            data-target=".navbar-collapse.show"
                        >
                            <a
                                routerLink="/pricing"
                                routerLinkActive="active"
                                class="nav-link"
                                >Pricing</a
                            >
                        </li> -->

            <li
              class="nav-item"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            >
              <a
                routerLink="/contact"
                routerLinkActive="active"
                class="nav-link"
                >Contacts</a
              >
            </li>
          </ul>

          <div class="others-options">
            <div class="option-item">
              <i class="search-btn flaticon-search"></i>
              <i class="close-btn fas fa-times"></i>
              <div class="search-overlay search-popup">
                <div class="search-box">
                  <form class="search-form">
                    <input
                      class="search-input"
                      name="search"
                      placeholder="Search"
                      type="text"
                    />
                    <button class="search-button" type="submit">
                      <i class="fas fa-search"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>

            <a routerLink="/marketplace" class="btn btn-primary">
              <i class="fas fa-bullhorn"></i>
              Marketplace</a
            >
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>
<!-- End Navbar Area -->
