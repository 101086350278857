<!-- Start Page Title Area -->
<div
  class="page-title-area"
  [ngStyle]="{
    backgroundImage: 'url(' + serviceDetails?.imgs.cover + ')',
    background:
      'linear-gradient(to bottom right, #082cf98c, #07737fa1), url(' +
      serviceDetails?.imgs.cover +
      ')'
  }"
>
  <div class="container">
    <div class="page-title-content">
      <h2>{{ serviceDetails?.name }}</h2>
      <ul>
        <li><a routerLink="/">Accueil</a></li>
        <li><a routerLink="/services">Services</a></li>
        <li>{{ serviceDetails?.slug }}</li>
      </ul>
    </div>
  </div>

  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area ptb-110">
  <div class="container">
    <div class="services-details-overview">
      <div class="services-details-desc">
        <h3>Desciption</h3>
        <p *ngFor="let desc of serviceDetails?.categories?.description">
          {{ desc }}
        </p>
      </div>

      <div class="services-details-image wow fadeInUp">
        <img src="{{ serviceDetails?.imgs?.img1 }}" alt="image" />
      </div>
    </div>

    <div class="services-details-overview">
      <div class="services-details-image wow fadeInUp">
        <img src="{{ serviceDetails?.imgs?.img2 }}" alt="image" />
      </div>

      <div class="services-details-desc">
        <h3>Domaines</h3>
        <!-- <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry’s standard dummy text ever
          since the 1500s, when an unknown printer.
        </p> -->

        <div class="services-details-accordion">
          <ul class="accordion">
            <li
              class="accordion-item"
              *ngFor="
                let categ of serviceDetails?.categories?.listCateg;
                let current = index
              "
              [ngClass]="{ active: activeIndex === current }"
            >
              <!-- {{ activeIndex === current }} -->
              <a
                class="accordion-title"
                [ngClass]="{ active: activeIndex === current }"
                (click)="switchActive(current)"
              >
                <i class="fas fa-plus"></i>
                {{ categ.name }}
              </a>
              <div class="accordion-content" *ngIf="activeIndex === current">
                <p>
                  {{ categ.description }}
                </p>
                <div>
                  <button
                    class="btn tag"
                    *ngFor="let tag of categ.listTechnologie; let i = index"
                    [ngStyle]="{ background: colors[i] }"
                  >
                    {{ tag }}
                  </button>
                </div>
              </div>
            </li>

            <!-- <li class="accordion-item">
              <a class="accordion-title">
                <i class="fas fa-plus"></i>
                Is Smart Lock required for instant apps?
              </a>
              <p class="accordion-content">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida.
              </p>
            </li> -->

            <!-- <li class="accordion-item">
              <a class="accordion-title">
                <i class="fas fa-plus"></i>
                Why Choose Our Services In Your Business?
              </a>
              <p class="accordion-content">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida.
              </p>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Services Details Area -->
