<!-- Start About Area -->
<section class="about-area ptb-110">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="about-image">
          <img src="assets/img/about/cloud-1.jpg" alt="image" />
          <img src="assets/img/about/cloud-2.jpg" alt="image" />
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="about-content">
          <h2>Clarize Technology Group, votre partenaire proche</h2>
          <p>
            Clarize Technology Group est une société de technologie et conseil
            stratégique spécialisée dans le management des processus
            d’innovation liés aux hautes technologies, dans la conception de
            solutions technologiques avancées, l’identification et le
            positionnement de solutions technologiques en rapport avec des
            problématiques précises.
          </p>
          <p>
            Notre mission est d’accélérer le développement et stimuler la
            compétitivité des organisations en offrant une panoplie d’expertises
            technologiques et sectorielles.
          </p>
          <p>
            Nous garantissons des produits, services, solutions numériques de
            pointe et des technologies émergentes qui répondent aux exigences de
            nos clients.
          </p>
          <!-- <a *ngIf="showMore" routerLink="/about" class="btn btn-primary"
            >Voir plus</a 
          >-->
        </div>
      </div>
    </div>
  </div>

  <div class="shape-img1">
    <img src="assets/img/shape/shape1.png" alt="image" />
  </div>
  <div class="shape-img2">
    <img src="assets/img/shape/shape2.svg" alt="image" />
  </div>
  <div class="shape-img3">
    <img src="assets/img/shape/shape3.png" alt="image" />
  </div>
  <div class="shape-img4">
    <img src="assets/img/shape/shape4.svg" alt="image" />
  </div>
  <div class="shape-img5">
    <img src="assets/img/shape/shape5.svg" alt="image" />
  </div>
  <div class="shape-img6">
    <img src="assets/img/shape/shape6.png" alt="image" />
  </div>
  <div class="dot-shape1">
    <img src="assets/img/shape/dot1.png" alt="image" />
  </div>
  <div class="dot-shape2">
    <img src="assets/img/shape/dot3.png" alt="image" />
  </div>
</section>
<!-- End About Area -->
