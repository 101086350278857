import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/layout/header/header.component";
import { FooterComponent } from "./components/layout/footer/footer.component";
// import { HomeComponent } from "./components/pages/home/home.component";
// import { AboutAreaComponent } from "./components/about-area/about-area.component";
// import { PartnerAreaComponent } from "./components/partner-area/partner-area.component";
// import { FeedbackComponent } from "./components/feedback/feedback.component";
// import { FreeTrialAreaComponent } from "./components/free-trial-area/free-trial-area.component";
// import { AboutComponent } from "./components/pages/about/about.component";
// import { ServicesComponent } from "./components/pages/services/services.component";
import { ServiceDetailsComponent } from "./components/pages/service-details/service-details.component";
import { NotFoundComponent } from "./components/pages/not-found/not-found.component";
// import { ContactComponent } from "./components/pages/contact/contact.component";
import { ProjectsComponent } from "./components/pages/projects/projects.component";
import { ProjectDetailsComponent } from "./components/pages/project-details/project-details.component";
// import { AngularFireModule } from "@angular/fire";
// import {
//   ScreenTrackingService,
//   UserTrackingService,
// } from "@angular/fire/analytics";
import { environment } from "src/environments/environment";
// import { OurValuesComponent } from "./components/our-values/our-values.component";
// import { OurTeamComponent } from "./components/our-team/our-team.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlashNotificationComponent } from "./services/notifier.service";
import { HttpClientModule } from "@angular/common/http";
import { ServiceWorkerModule } from "@angular/service-worker";
import { ComingSoonComponent } from "./components/pages/coming-soon/coming-soon.component";
import { SharedModule } from "./core/shared/shared.module";
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    // HomeComponent,
    FlashNotificationComponent,
    // PricingPlanComponent,
    // FreeTrialAreaComponent,
    // AboutComponent,
    ComingSoonComponent,
    // TeamComponent,
    // PricingComponent,
    // ServicesComponent,
    ServiceDetailsComponent,
    // BlogComponent,
    // BlogDetailsComponent,
    // PartnerComponent,
    NotFoundComponent,
    // FaqComponent,
    // ComingSoonComponent,
    // ContactComponent,
    // MachineLearningComponent,
    // MachineLearningTwoComponent,
    ProjectsComponent,
    ProjectDetailsComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    // AngularFireModule.initializeApp(environment.firebaseConfig),
    // AngularFireAnalyticsModule.,
    // ScreenTrackingService,
    // UserTrackingService,
    BrowserAnimationsModule,
    MatIconModule,
    MatSnackBarModule,
    MatDialogModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
