<!-- Start Partner Area -->
<section class="partner-area ptb-110 bg-f2f6f9">
  <div class="container">
    <div class="section-title">
      <h2>Nos clients et partenaires</h2>
      <p>
        Clarize, une communauté axée sur une promotion technologique de
        l'Afrique.
      </p>
    </div>

    <div class="customers-partner-list">
      <div class="partner-item">
        <a href="https://www.petrosents.com/" target="_blank">
          <img src="assets/img/partner/petrosen.jpg" alt="image" />
        </a>
      </div>

      <div class="partner-item">
        <a href="https:/acmt-digital.com/" target="_blank">
          <img src="assets/img/partner/acmt.png" alt="image" />
        </a>
      </div>

      <div class="partner-item">
        <a href="http://www.helix-techno.com/" target="_blank">
          <img src="assets/img/partner/helix.jpg" alt="image" />
        </a>
      </div>
      <div class="partner-item">
        <a href="https://goexpress-online.com/" target="_blank">
          <img src="assets/img/partner/goexpress.png" alt="image" />
        </a>
      </div>

      <!-- <div class="partner-item">
              <a>
                <img src="assets/img/partner/unido.jpg" alt="image" />
              </a>
            </div> -->

      <div class="partner-item">
        <a href="https://wutiko.com/" target="_blank">
          <img src="assets/img/partner/wutiko-yellow.jfif" alt="image" />
        </a>
      </div>

      <div class="partner-item">
        <a href="https://nsia-digital.com/" target="_blank">
          <img src="assets/img/partner/nsia.png" alt="image" />
        </a>
      </div>

      <div class="partner-item">
        <a>
          <img src="assets/img/partner/light-inc.JPG" alt="image" />
        </a>
      </div>
      <!--
            <div class="partner-item">
                <a >
                    <img src="assets/img/partner/partner1.png" alt="image">
                </a>
            </div>

            <div class="partner-item">
                <a >
                    <img src="assets/img/partner/partner2.png" alt="image">
                </a>
            </div>

            <div class="partner-item">
                <a >
                    <img src="assets/img/partner/partner3.png" alt="image">
                </a>
            </div> -->
    </div>
  </div>
</section>
<!-- End Partner Area -->
