import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
// import { HomeComponent } from "./components/pages/home/home.component";
// import { AboutComponent } from "./components/pages/about/about.component";
//  import { TeamComponent } from "./components/pages/team/team.component";
// import { PricingComponent } from "./components/pages/pricing/pricing.component";
// import { ServicesComponent } from "./components/pages/services/services.component";
import { ServiceDetailsComponent } from "./components/pages/service-details/service-details.component";
// import { BlogComponent } from "./components/pages/blog/blog.component";
// import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
// import { PartnerComponent } from "./components/pages/partner/partner.component";
import { NotFoundComponent } from "./components/pages/not-found/not-found.component";
// import { FaqComponent } from "./components/pages/faq/faq.component";
import { ComingSoonComponent } from "./components/pages/coming-soon/coming-soon.component";
// import { ContactComponent } from "./components/pages/contact/contact.component";
// import { MachineLearningComponent } from "./components/pages/machine-learning/machine-learning.component";
// import { MachineLearningTwoComponent } from "./components/pages/machine-learning-two/machine-learning-two.component";
import { ProjectsComponent } from "./components/pages/projects/projects.component";
import { ProjectDetailsComponent } from "./components/pages/project-details/project-details.component";

const routes: Routes = [
  { path: "home", loadChildren: () =>import('./components/pages/home/home.module').then(m => m.HomeModule)},
  { path: "about", loadChildren: () =>import('./components/pages/about/about.module').then(m => m.AboutModule)  },
  { path: "services",  loadChildren: () =>import('./components/pages/services/services.module').then(m => m.ServicesModule) },
  { path: "service-details/:slug", component: ServiceDetailsComponent },
  { path: "projects/:slug", component: ProjectDetailsComponent },
  { path: "projects", component: ProjectsComponent },
  { path: "contact",  loadChildren: () =>import('./components/pages/contact/contact.module').then(m => m.ContactModule) },
  { path: "marketplace-apps",  loadChildren: () =>import('./components/pages/marketplace/marketplace.module').then(m => m.MarketplaceModule) },
  { path: "marketplace", component: ComingSoonComponent },
  // { path: "team", component: TeamComponent },
  // { path: "pricing", component: PricingComponent },
  // { path: "project-details", component: ProjectDetailsComponent },
  // { path: "machine-learning", component: MachineLearningComponent },
  // { path: "machine-learning-2", component: MachineLearningTwoComponent },
  // { path: "blog", component: BlogComponent },
  // { path: "blog-details", component: BlogDetailsComponent },
  // { path: "partner", component: PartnerComponent },
  // { path: "faq", component: FaqComponent },
  // Here add new pages component

  { path: "", redirectTo: "home", pathMatch: "full" }, // This line will remain down from the whole pages component list
  { path: "**", component: NotFoundComponent }, // This line will remain down from the whole pages component list
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
