<!-- Start Footer Area -->
<footer class="footer-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <div class="logo">
            <a routerLink="/">
              <img src="assets/img/white-logo.png" alt="image" />
            </a>
            <p>
              Conseil stratégique spécialisée dans le management des processus
              d’innovation liés aux hautes technologies.
            </p>
          </div>
          <ul class="social">
            <li>
              <a
                href="https://www.linkedin.com/company/clarize-technology-group/"
                target="_blank"
                ><i class="fab fa-linkedin-in"></i
              ></a>
            </li>
            <li>
              <a href="https://twitter.com/ClarizeGroup" target="_blank"
                ><i class="flaticon-twitter"></i
              ></a>
            </li>
            <li>
              <a href="https://web.facebook.com/clarizegroup" target="_blank"
                ><i class="flaticon-facebook-letter-logo"></i
              ></a>
            </li>
            <li>
              <a href="https://www.instagram.com/clarizegroup/" target="_blank"
                ><i class="flaticon-instagram-logo"></i
              ></a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UC-EvPSViTybbLY5tggTOBvw"
                target="_blank"
                ><i class="flaticon-youtube-play-button"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <h3>Services</h3>

          <ul class="footer-services-list">
            <li>
              <a routerLink="/service-details/software-engineers"
                >Product Engineering</a
              >
            </li>
            <li>
              <a routerLink="/service-details/software-engineers;active=2"
                >Mobile Applications</a
              >
            </li>
            <li><a routerLink="/service-details/iot-cloud">IOT & Cloud</a></li>
            <li>
              <a routerLink="/service-details/data-analysts"
                >Big Data Analysis</a
              >
            </li>
            <li>
              <a routerLink="/service-details/security-surveillance">
                Surveillance & Security</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <h3>Quick Links</h3>

          <ul class="quick-links-list">
            <li><a routerLink="about">About Us</a></li>
            <!-- <li><a routerLink="/">Blog</a></li> -->
            <li><a routerLink="/contact">Contact</a></li>
            <li><a routerLink="/services">Services</a></li>
            <li><a routerLink="/projects">Applications</a></li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <h3>Contacts</h3>

          <ul class="footer-contact-list">
            <li>
              <span>Adresse:</span>Hann Marites Ilot D26 <br />
              Dakar, Sénégal
            </li>
            <li>
              <span>Email:</span>
              <a href="mailto:clarizegroup@gmail.com">clarizegroup@gmail.com</a>
              <!-- <a href="mailto:contacts@clarizegroup.com"
                >contacts@clarizegroup.com</a
              > -->
            </li>
            <li>
              <span>Phone:</span>
              <a href="tel:+221338328120">+221 33 832 81 20</a>/
              <a href="tel:+221778554015">+221 77 855 40 15</a>/
              <a href="tel:+221763845454">+221 76 384 54 54</a>
            </li>
           <!-- <li><span>NINEA:</span> 008627757</li>
            <li>
              <span>RCCM:</span>
              SN.DKR.2021.A.19740
            </li>-->
            <!-- <li><span>Fax:</span> <a href="tel:+44587154756">+44 785 4578964</a></li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright-area">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6 col-sm-6">
          <p>
            &copy; 2021 <a routerLink="/">Clarize Technology Group</a>. All
            Rights Reserved.
          </p>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6">
          <ul>
            <li><a routerLink="/">Privacy Policy</a></li>
            <li><a routerLink="/">Terms & Conditions</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="circle-map">
    <img src="assets/img/circle-map.png" alt="image" />
  </div>

  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
</footer>
<!-- End Footer Area -->

<div class="go-top">
  <i class="fas fa-arrow-up"></i><i class="fas fa-arrow-up"></i>
</div>
