import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AboutAreaComponent } from "src/app/components/about-area/about-area.component";
import { OurTeamComponent } from "src/app/components/our-team/our-team.component";
import { OurValuesComponent } from "src/app/components/our-values/our-values.component";
import { PartnerAreaComponent } from "src/app/components/partner-area/partner-area.component";
import { FeedbackComponent } from "src/app/components/feedback/feedback.component";
import { FreeTrialAreaComponent } from "src/app/components/free-trial-area/free-trial-area.component";
import { FormsModule } from "@angular/forms";

const components = [
  OurValuesComponent,
  OurTeamComponent,
  AboutAreaComponent,
  FreeTrialAreaComponent,
  PartnerAreaComponent,
  FeedbackComponent,
];

@NgModule({
  declarations: [components],
  imports: [FormsModule, CommonModule],
  exports: [components],
})
export class SharedModule {}
