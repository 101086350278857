<!-- Start Free Trial Area -->
<section class="free-trial-area">
  <div class="row m-0">
    <div class="col-lg-6 col-md-12 p-0">
      <div class="free-trial-image">
        <img src="assets/img/keep.jpg" alt="image" />
      </div>
    </div>

    <div class="col-lg-6 col-md-12 p-0">
      <div class="free-trial-content">
        <h2>Rester connecté</h2>
        <form class="newsletter-form">
          <input
            id="name"
            class="input-newsletter"
            [(ngModel)]="name"
            [ngModelOptions]="{ standalone: true }"
            placeholder="Entrer votre nom"
            email="true"
          />
          <input
            type="email"
            id="email"
            class="input-newsletter"
            [(ngModel)]="email"
            [ngModelOptions]="{ standalone: true }"
            placeholder="Entrer votre email"
            email="true"
          />
          <button
            [disabled]="isSaving"
            type="submit"
            (click)="subscribeToNewsletter()"
          >
            {{
              isSaving === false ? "Souscription" : "Souscription en cours ..."
            }}
          </button>
        </form>
      </div>
    </div>
  </div>
  <p>
    Soyez les premiers à découvrir nos dernières offres et solutions
    technologiques!
  </p>
</section>
<!-- End Free Trial Area -->
