import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class TestimonialsService {
  private _testimonials = [
    {
      name: "Hamadounn Ben Aya",
      title: "CEO ACMT Digital",
      photo: "https://acmt-digital.com/wp-content/uploads/2020/10/Capture.png",
      content: `Clarize TG est un partenaire clé du cabinet ACMT avec qui il a eu a réaliser beaucoup de missions.
    Nous avons travaillé ensemble pour des grands comptes comme NSIA ASSURNANCES, PETROSEN et aussi sur le devemoppement de produits digitaux.
    Nous saluons le professionalisme du cabinet et confirmons le niveau de qualité des prestations fournies.`,
      cover: "assets/img/client/customers-1.jpg",
    },
    {
      name: "Sagar Diop",
      title: "CEO/Founder Hebdomagazine",
      photo:
        "https://lh3.googleusercontent.com/a-/AOh14Gih1YX5WCHZAcLM4UqA7t7zQwolyCVNavMJCqVtrw=s64-p-k-rw-no",
      content: `Le projet est réalisé parfaitement. J'aime votre simplicité.`,
      cover: "assets/img/client/customers-3.webp",
    },
    {
      name: "Magib Fofana",
      title: "Projet Manager at Helix",
      photo:
        "https://lh3.googleusercontent.com/a-/AOh14GhUKPylpZhyD8GX6H2sy651UtkePjSkaQzNHQxiLA=s64-p-k-rw-no",
      content: `Great work everytime`,
      cover: "assets/img/client/customers-2.jpg",
    },
  ];
  constructor() {}
  get testimonials() {
    return this._testimonials.slice();
  }
}
